import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDictionary } from "../../../service/dictionary";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import { SubmitHandler, useForm, Control } from "react-hook-form";
import { useSalesforceOpportunities } from "../../../salesforce/opportunities";
import { Select } from "../../../components/Select";
import { useNotifications } from "../../../notifications";
import { Header } from "../../Header";
import { ContentBox } from "../../../components/ContentBox";
import useQueryParams from "../../../utils/searchparams";

interface FormFields {
  type: string;
  opportunity: string;
  parentContract: number | null;
}

function OpportunitiesField(props: { accountId: string; control: Control; renewalOnly: boolean }): JSX.Element {
  const { isLoading, isError, result } = useSalesforceOpportunities(props.accountId);
  if (isLoading) return <CircularProgress />;
  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        An unknown error occurred when fetching data from Salesforce, please try again.
      </Alert>
    );
  }
  const opportunities = props.renewalOnly
    ? result!.filter((opportunity) => opportunity.type === "Existing Business")
    : result!;
  return (
    <>
      {result != null && (
        <>
          <Select
            control={props.control}
            name="opportunity"
            helpText="Opportunity to base the contract on"
            label="SFDC opportunity"
            default=""
            required
            rules={{ required: true }}
          >
            {
              [
                <MenuItem key="0" value="">
                  -----
                </MenuItem>,
              ].concat(
                opportunities.map((opportunity) => (
                  <MenuItem key={opportunity.id} value={opportunity.id}>
                    {opportunity.id}: {opportunity.name}
                  </MenuItem>
                ))
              ) as any
            }
          </Select>
          {result!.length == 0 && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              Could not find suitable opportunities in Salesforce.
              <br />
              Please make sure the account has closed opportunities.
            </Alert>
          )}
        </>
      )}
    </>
  );
}

export function Create(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { dictionary } = useDictionary();
  const history = useHistory();
  const { addNotification } = useNotifications();
  const { contractId, renewalOnly } = useQueryParams();
  const isRenewal = renewalOnly === "true";

  const { control, handleSubmit, watch, clearErrors, setValue } = useForm<FormFields>();
  const onSubmit: SubmitHandler<FormFields> = (data: FormFields) => {
    const params = new URLSearchParams();
    params.set("type", data.type);
    params.set("opportunity", data.opportunity);
    if (data.parentContract) params.set("parent", String(data.parentContract));
    if (contractId) params.set("contractId", contractId);

    addNotification({
      text: "Opportunity data imported, please review and create the contract",
      level: "info",
    });
    history.push({
      pathname: `/accounts/${accountId}/contracts/preview`,
      search: `?${params.toString()}`,
    });
  };

  let dealTypes = Object.entries(dictionary.DEAL_TYPES);
  if (isRenewal) {
    dealTypes = dealTypes.filter((type) => type[0] === "renewal" || type[0] === "renewal_extension");
  }

  return (
    <>
      <Header accountId={accountId} page="New contract" />

      <ContentBox>
        <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <Select
                name="type"
                control={control as any}
                default={isRenewal ? "renewal" : "new_business"}
                rules={{ required: true }}
                disabled={isRenewal}
                label="Type"
                required
              >
                {
                  dealTypes.map((dealType) => (
                    <MenuItem key={dealType[0]} value={dealType[0]}>
                      {dealType[1]}
                    </MenuItem>
                  )) as any
                }
              </Select>
            </Grid>
            <Grid item xs={7}>
              <OpportunitiesField accountId={accountId} control={control as any} renewalOnly={isRenewal} />
            </Grid>

            <Grid item xs={12}>
              <Button variant="text" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                Preview
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ContentBox>
    </>
  );
}

import * as React from "react";
import type { Variant } from "@mui/material/styles/createTypography";
import { useHistory } from "react-router";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ListItemText from "@mui/material/ListItemText";
import TuneIcon from "@mui/icons-material/Tune";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useStyles } from "../../../components/ContentBox";
import { usePermissions } from "../../../service/auth";

export function ContractContentBox(props: {
  children: React.ReactNode;
  header?: string;
  headerVariant?: Variant;
  contractId: string;
  accountId: string;
  shouldShowRenewButton?: boolean;
}): JSX.Element {
  const { hasPermission } = usePermissions();
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(null);
  const history = useHistory();

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Typography gutterBottom variant={props.headerVariant || "h5"}>
                  {props.header}
                </Typography>
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Grid item>
                  <IconButton aria-haspopup="true" onClick={handleClick} color="inherit">
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    id="contract-menu"
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                  >
                    <MenuList>
                      {hasPermission("netsuite") && (
                        <MenuItem
                          onClick={() =>
                            history.push(`/accounts/${props.accountId}/contracts/${props.contractId}/netsuite/`)
                          }
                        >
                          <ListItemIcon>
                            <AccountBalanceIcon />
                          </ListItemIcon>
                          <ListItemText>Netsuite</ListItemText>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() =>
                          history.push(`/accounts/${props.accountId}/contracts/${props.contractId}/values/`)
                        }
                      >
                        <ListItemIcon>
                          <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Financial Data</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() =>
                          history.push(`/accounts/${props.accountId}/contracts/${props.contractId}/annotations/`)
                        }
                      >
                        <ListItemIcon>
                          <TuneIcon />
                        </ListItemIcon>
                        <ListItemText>Annotate Use Cases</ListItemText>
                      </MenuItem>
                      {props.shouldShowRenewButton && (
                        <MenuItem
                          onClick={() => {
                            const params = new URLSearchParams();
                            params.set("renewalOnly", "true");
                            params.set("contractId", props.contractId);
                            history.push({
                              pathname: `/accounts/${props.accountId}/contracts/new`,
                              search: `?${params.toString()}`,
                            });
                          }}
                        >
                          <ListItemIcon>
                            <AutorenewIcon />
                          </ListItemIcon>
                          <ListItemText>Renew</ListItemText>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import type { Theme } from "@mui/material/styles";
import type { Variant } from "@mui/material/styles/createTypography";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import type { GridSize } from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: "10px",
    },
    container: {
      padding: "10px 5px",
      marginBottom: "10px",
    },
  })
);

export function ContentBox(props: {
  children: React.ReactNode;
  header?: string;
  headerVariant?: Variant;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Typography gutterBottom variant={props.headerVariant || "h5"}>
              {props.header}
            </Typography>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

export function ControlContentBox(props: {
  children: React.ReactNode;
  controls?: React.ReactNode;
  controlWidth?: number;
  header?: string;
  headerVariant?: Variant;
}) {
  const classes = useStyles();
  // 4 arbitrarily chosen as the default control width
  const titleWidth: GridSize = props.controls ? ((12 - (props.controlWidth || 4)) as GridSize) : (12 as GridSize);
  const controlWidth: GridSize = props.controls ? ((props.controlWidth || 4) as GridSize) : (12 as GridSize);

  return (
    <Card className={classes.container}>
      <CardContent>
        {props.header && (
          <>
            <Grid container xs={12}>
              <Grid item xs={titleWidth} alignContent="flex-end">
                <Typography gutterBottom variant={props.headerVariant || "h5"}>
                  {props.header}
                </Typography>
              </Grid>
              <Grid className={classes.header} item xs={controlWidth} justifyContent="flex-end">
                {props.controls && <>{props.controls}</>}
              </Grid>
            </Grid>
          </>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

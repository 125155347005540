import { LineItem } from "../../../../service/lineitems";
import { ResponsiveNetwork } from "@nivo/network";
import { NetworkLink } from "./NetworkLink";
import { BIG_NODE_SIZE, SMALL_NODE_SIZE } from "./NetworkNode";
import DealTypeLabel, { DEAL_TYPES } from "./DealTypeLabel";
import { Contract, DealType } from "../../../../service/contracts";
import Label from "./Label";
import uniqBy from "lodash/uniqBy";

const CURRENT_LI_COLOR = "rgb(124,124,124)";
// List of deal types to show in the network graph
const NETWORK_DEAL_TYPES: (keyof typeof DEAL_TYPES)[] = [
  "new_business",
  "upsell",
  "upsell_new",
  "growth",
  "renewal",
  "renewal_extension",
];

function StyledTooltip(props: { children: string | string[] }): JSX.Element {
  const style = {
    backgroundColor: "rgb(164,164,164)",
    padding: "5px 10px",
    fontSize: "0.8em",
    borderRadius: "10px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  };
  return <div style={style}>{props.children}</div>;
}

export default function Network(props: {
  lineItem: LineItem;
  contracts: Contract[];
  otherLineItems: LineItem[];
  children: number[];
  parents: number[];
  onMouseEnter: (liId: number) => void;
  onMouseLeave: () => void;
  highlightedLineItem?: number;
}): JSX.Element {
  const contractsById: { [key: number]: Contract } = Object.fromEntries(props.contracts.map((c) => [c.id, c]));

  function getDealType(lineItem: LineItem): DealType | null {
    const contractId = lineItem.contractId;
    if (contractId == null || !contractsById[contractId]) return null;
    return contractsById[contractId].dealTypes[0];
  }

  const lineItemsById = Object.fromEntries(props.otherLineItems.map((li) => [li.id, li]));
  lineItemsById[props.lineItem.id!] = props.lineItem;

  function findLinkedLineItems(node: { children: number[]; parents: number[] }, processed: number[]): number[] {
    let connections = node.children.concat(node.parents);
    connections.forEach((c) => {
      if (!processed.includes(c)) {
        processed.push(c);
        connections = connections.concat(findLinkedLineItems(lineItemsById[c], processed));
      }
    });
    return connections;
  }

  const connected = findLinkedLineItems(props, [props.lineItem.id!]);

  const otherLinks = props.otherLineItems.flatMap((li) =>
    li.children
      .filter((l) => l != props.lineItem.id && connected.includes(l))
      .map((l) => ({
        source: li.id!.toString(),
        target: l.toString(),
      }))
      .concat(
        li.parents
          .filter((l) => l != props.lineItem.id && connected.includes(l))
          .map((l) => ({
            source: l.toString(),
            target: li.id!.toString(),
          }))
      )
  );

  const data = {
    nodes: [
      {
        id: props.lineItem.id!.toString(),
        size: BIG_NODE_SIZE,
        label: props.lineItem.id,
        color: CURRENT_LI_COLOR,
      },
    ].concat(
      props.otherLineItems
        .filter((li) => connected.includes(li.id!))
        .map((li) => [li, getDealType(li)] as [LineItem, DealType | null])
        .filter(([_, dealType]) => dealType != null && NETWORK_DEAL_TYPES.includes(dealType))
        .map(([li, dealType]) => {
          return {
            id: li.id!.toString(),
            size: SMALL_NODE_SIZE,
            label: li.id,
            color: DEAL_TYPES[dealType!].color,
          };
        })
    ),
    links: props.children
      .map((l) => ({
        source: props.lineItem.id!.toString(),
        target: l.toString(),
      }))
      .concat(
        props.parents.map((l) => ({
          source: l.toString(),
          target: props.lineItem.id!.toString(),
        }))
      )
      .concat(otherLinks),
  };

  data.links = uniqBy(data.links, (x) => x.source + x.target);
  return (
    <div>
      <div style={{ height: "500px" }}>
        <ResponsiveNetwork
          annotations={
            props.highlightedLineItem
              ? [
                  {
                    type: "circle",
                    match: {
                      id: props.highlightedLineItem.toString(),
                    },
                    note: "Selected line item",
                    noteX: 30,
                    noteY: 30,
                    offset: 0,
                    noteTextOffset: 5,
                  },
                ]
              : undefined
          }
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          linkDistance={100}
          centeringStrength={0.1}
          repulsivity={50}
          nodeSize={(n) => n.size}
          activeNodeSize={(n) => 1.1 * n.size}
          inactiveNodeSize={(n) => n.size}
          nodeColor={(n) => n.color}
          linkComponent={NetworkLink}
          linkThickness={3}
          linkBlendMode="multiply"
          motionConfig="gentle"
          onMouseEnter={(node) => props.onMouseEnter(parseInt(node.id))}
          onMouseLeave={() => props.onMouseLeave()}
          nodeTooltip={({ node }) => {
            const li = lineItemsById[node.id];
            return (
              <StyledTooltip>
                {li.contractId}: {contractsById[li.contractId].name}
              </StyledTooltip>
            );
          }}
        />
      </div>
      {NETWORK_DEAL_TYPES.map((dealType) => (
        <DealTypeLabel key={dealType} dealType={dealType} filled noIcon shortLabel />
      ))}
      <Label label="current LI" color={CURRENT_LI_COLOR} filled />
    </div>
  );
}

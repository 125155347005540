import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { useSalesforceOpportunity } from "../../../salesforce/opportunities";
import { NotFound } from "../NotFound";
import { useSalesforceOpportunityLineItems } from "../../../salesforce/lineitems";
import { Contract, contractFromSalesforce, DealType, useContractModifier } from "../../../service/contracts";
import { useLineItemModifier, LineItem, lineItemFromSF, SFImportError, useLineItems } from "../../../service/lineitems";
import { useSalesforceAccount } from "../../../salesforce/accounts";
import { ContractForm } from "./components/ContractForm";
import { ContractLineItems } from "./components/ContractLineItems";
import { ConfirmedButton } from "../../../components/ConfirmedButton";
import { APIError, ValidationError } from "../../../service/api";
import { useNotifications } from "../../../notifications";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { useDictionary } from "../../../service/dictionary";
import { Header } from "../../Header";
import useQueryParams from "../../../utils/searchparams";

export function Preview(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();

  const history = useHistory();
  const { addNotification } = useNotifications();
  const { loadedDictionary } = useDictionary();
  const { type, opportunity, contractId, parent } = useQueryParams();

  const [contract, setContract] = useState<Contract | null>(null);
  const [contractErrors, setContractErrors] = useState<{ [key: string]: string[] } | null>(null);
  const [lineItems, setLineItems] = useState<LineItem[] | null>(null);
  const [lineItemsErrors, setLineItemsErrors] = useState<{ [key: number]: { [key: string]: string } } | null>(null);

  const contractModifier = useContractModifier(accountId);
  const lineItemModifier = useLineItemModifier(accountId);

  if (!type || !opportunity) return <NotFound />;

  const {
    isLoading: isLoadingSfdcAccount,
    isError: isErrorSfdcAccount,
    result: sfdcAccount,
  } = useSalesforceAccount(accountId);
  // Use the opportunity id which was passed from the previous component.
  const {
    isLoading: isLoadingSfdcOpportunity,
    isError: isErrorSfdcOpportunity,
    result: sfdcOpportunity,
  } = useSalesforceOpportunity(opportunity);
  const {
    isLoading: isLoadingSfdcLineitems,
    isError: isErrorSfdcLineitems,
    result: sfdcLineitems,
  } = useSalesforceOpportunityLineItems(opportunity);

  let submsLineItems: LineItem[] | undefined;
  let submsLineItemsError: APIError;
  if (type === "renewal" || type === "renewal_extension") {
    const {
      isLoading: isLoadingSubmsLineItems,
      isError: isErrorSubmsLineItems,
      error: submsLineItemsError,
      data,
    } = useLineItems(accountId, 0, { contract: contractId });
    submsLineItems = data;
  }

  useEffect(() => {
    if (lineItems === null && sfdcLineitems) {
      let importedLineItems;
      try {
        if (type === "renewal" || type === "renewal_extension") {
          if (submsLineItems) {
            importedLineItems = submsLineItems.map((lineItem) => {
              lineItem.id = null;
              return lineItem;
            });
          } else {
            addNotification({
              text: submsLineItemsError?.message || "Error fetching line items from SubMS for renewal",
              level: "error",
            });
          }
        } else {
          importedLineItems = sfdcLineitems.map((lineItem) => lineItemFromSF(lineItem));
        }
      } catch (err) {
        if (err instanceof SFImportError) {
          addNotification({
            text: err.message,
            level: "error",
          });
        } else {
          throw err;
        }
      }

      if (importedLineItems) {
        setLineItems(importedLineItems);
        if (uniq(importedLineItems.map((li) => li.currency)).length > 1) {
          addNotification({
            text: "Currency is not consistent across line items",
            level: "warning",
          });
        }
        if (importedLineItems.length == 0) {
          addNotification({
            text: "Could not import any line items from the opportunity, please check the Salesforce data",
            level: "warning",
          });
        }
      }
    }

    if (contract === null && sfdcOpportunity && sfdcAccount && loadedDictionary) {
      setContract(
        contractFromSalesforce(
          sfdcOpportunity,
          accountId,
          parent ? parseInt(parent) : null,
          loadedDictionary,
          type as DealType | undefined
        )
      );
      if (sfdcOpportunity.linkToConfirmationOfTierGrowth) {
        addNotification({
          text: "Confirmation of tier growth documents default to Email confirmation",
          level: "warning",
        });
      }
    }
  }, [lineItems, sfdcOpportunity, sfdcAccount, sfdcLineitems, submsLineItems]);

  async function handleLineItemsChange(lineItem: LineItem, idx: number): Promise<boolean> {
    setLineItemsErrors(omit(lineItemsErrors || {}, [idx]));
    if (idx == -1) {
      setLineItems(lineItems!.concat([lineItem]));
    } else {
      setLineItems(lineItems!.map((li, liIdx) => (liIdx == idx ? lineItem : li)));
    }
    return true;
  }

  async function handleLineItemsDelete(lineItem: LineItem, idx: number): Promise<boolean> {
    setLineItems(lineItems!.filter((_, liIdx) => liIdx != idx));
    return true;
  }

  function handleCancel() {
    history.goBack();
  }

  async function handleCreate() {
    if (!contract || !lineItems) return;
    setContractErrors(null);
    setLineItemsErrors(null);
    let newContract;
    try {
      newContract = contract.id ? await contractModifier.update(contract) : await contractModifier.create(contract);
    } catch (error) {
      if (error instanceof ValidationError) {
        setContractErrors(error.errors);
        return;
      }
      throw error;
    }
    setContract(newContract);

    for (let idx = 0; idx < lineItems.length; idx++) {
      const lineItem = lineItems[idx];
      const method: (lineItem: LineItem) => Promise<LineItem> = lineItem.id
        ? lineItemModifier.update
        : lineItemModifier.create;
      let newLineItem: any;
      try {
        newLineItem = await method(Object.assign(lineItems[idx], { contractId: newContract.id }));
      } catch (error) {
        if (error instanceof ValidationError) {
          setLineItemsErrors({ [idx]: error.errors } as any);
          return;
        }
        throw error;
      }
      setLineItems(lineItems.map((li, liIdx) => (liIdx == idx ? newLineItem : li)));
    }
    addNotification({
      text: "Contract created successfully",
      level: "success",
    });
    history.push(`/accounts/${accountId}/contracts/${newContract.id}/`);
  }

  async function setContractHelper(contract: Contract): Promise<boolean> {
    setContract(contract);
    return true;
  }

  return (
    <>
      <Header accountId={accountId} page="Preview contract" />

      {isLoadingSfdcAccount ? (
        <>
          ⏳ Salesforce Account loading...
          <br />
        </>
      ) : (
        <></>
      )}
      {isLoadingSfdcOpportunity ? (
        <>
          ⏳ Salesforce Opportunity loading...
          <br />
        </>
      ) : (
        <></>
      )}
      {isLoadingSfdcLineitems ? (
        <>
          ⏳ Salesforce Line Items loading...
          <br />
        </>
      ) : (
        <></>
      )}
      {isErrorSfdcAccount ? (
        <>
          ⚠ Salesforce Account error
          <br />
        </>
      ) : (
        <></>
      )}
      {isErrorSfdcOpportunity ? (
        <>
          ⚠ Salesforce Opportunity error
          <br />
        </>
      ) : (
        <></>
      )}
      {isErrorSfdcLineitems ? (
        <>
          ⚠ Salesforce Line Items error
          <br />
        </>
      ) : (
        <></>
      )}
      {sfdcAccount ? (
        <>
          ✅ Salesforce Account loaded.
          <br />
        </>
      ) : (
        <></>
      )}
      {sfdcOpportunity ? (
        <>
          ✅ Salesforce Opportunity loaded.
          <br />
        </>
      ) : (
        <></>
      )}
      {sfdcLineitems ? (
        <>
          ✅ Salesforce Line Items loaded.
          <br />
        </>
      ) : (
        <></>
      )}

      {contract && (
        <ContractForm
          errors={contractErrors}
          accountId={accountId}
          contract={contract}
          lineItems={lineItems as any}
          onChange={(changedContract) => setContractHelper(changedContract)}
          shouldShowRenewButton={false}
        />
      )}
      {lineItems != null && (
        <ContractLineItems
          errors={lineItemsErrors as any}
          lineItems={lineItems}
          onChange={handleLineItemsChange}
          onDelete={handleLineItemsDelete}
          allowSalesforceRefresh={false}
          contract={contract}
          accountId={accountId}
        />
      )}
      <Divider />
      <ConfirmedButton
        confirmationTitle={"Confirmation"}
        confirmationText={`Are you sure you want to cancel creation of this contract, it wasn't saved yet?`}
        onConfirmed={handleCancel}
      >
        Cancel
      </ConfirmedButton>
      <Button color="primary" variant="contained" onClick={handleCreate}>
        Create
      </Button>
    </>
  );
}
